<template>
    <div class="leaflet-control text-center" style="max-width: 500px;">
      
      <b-button v-if="!visible_legend" @click="visible_legend = !visible_legend" class="m-1" variant="outline-light" style="border-radius: 15px;"><i  class="fa-solid fa-circle-info"></i></b-button>
      <b-button v-if="visible_legend" @click="visible_legend = !visible_legend" class="m-1" variant="outline-danger" style="border-radius: 15px;"><i class="fa-solid fa-xmark"></i></b-button>
        <b-collapse  v-model="visible_legend" class="mt-2">
          <div class="text-center d-flex justify-content-center"> 
            <strong class="mb-2" style="font-size:16px" v-html="data.text"></strong>
          </div>
        <div class="">
        <div class=""  v-if="data.value==='geolocation'">
            <div v-for="(ll,index) in data" :key="index+'legend'">
              <div v-if="!array_not_show.includes(index)" class="d-flex px-4">
                <div style="position: relative;" >
                  <img :src="'/img/'+ll.icon" style="width:20px;"/>
                </div>
                <p class="px-2" style="font-size: 12px;text-align: left;">({{ll.number}}) {{ index }}</p>
              </div>
            </div>
            
        </div>  
        <div class=""  v-if="data.value!=='geolocation'">
            <div v-for="(ll,index) in data" :key="index+'legend'">
              <div v-if="!array_not_show.includes(index)" class="d-flex px-4">
                <div style="position: relative;" >
                  <i class="fa-solid fa-location-pin"  :style="{'color':ll.color,'font-size':'20px','border':'1rem solid;'}" ></i>
                </div>
                <p class="px-2" style="font-size: 12px;text-align: left;">({{ll.number}}) {{ index }}</p>
              </div>
            </div>
            
        </div>  
        </div>  
      </b-collapse>
        
    </div>
</template>


<script>
export default {
  props: {
    data: Object
  },
data(){
    return{
        visible_legend:true,
        legend_data_value:null,
        legend_data_col:null,
        title:null,
        array_not_show:['text','value','in_radio_group']
    }
},
  created(){
    console.log(this.data)
    //this.legend_data_color=this.data.color
    //this.legend_data_value=this.data.value
    //this.title=this.data.title
  }
}

</script>

<style >
.box3 {
    
    padding:8px
    /* height: 22px; */
}
@media screen and (max-width: 730px) {
  .box3 {
    font-size: 14px!important;
    width: 40px;
    /* height: 22px; */
}
}
</style>