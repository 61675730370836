<template>
    <div v-if="$store.state.poly_vertices">
        <div v-for="(point,ind) in $store.state.poly_vertices" :key="ind+'point'"> 
        <l-circle
          ref="circle_poly_vertices"
          :options="{ zIndex: 2 }"
          :lat-lng="[JSON.parse(point.geom).coordinates[1],JSON.parse(point.geom).coordinates[0]]"
          :radius="2"
          :color="'orange'"
        >
        <!--<l-tooltip>{{ JSON.parse(point.geom).coordinates[0] }} , {{ JSON.parse(point.geom).coordinates[1] }}</l-tooltip>-->
        
          <l-tooltip>{{ point.x }} , {{ point.y }}</l-tooltip>
        
      </l-circle>
      </div>
    </div>
</template>


<script>
//import L from "leaflet";
import axios_django from "../axios-auth";

export default {
  props: {
    live_zoom: Number,
   
  },
  components: {
  
  },
  data() {
    return {

      poly_vertices:null,
    }
  },
 
  mounted(){
    
    this.$nextTick(() => {
  
      //this.$refs.map1.mapObject.sync(this.$refs.map2.mapObject)
      //this.$refs.map2.mapObject.sync(this.$refs.map1.mapObject)
    });
  },
  async created(){
   
    
    this.loadPolyVertices()
    
  },
  methods:{

    loadPolyVertices(){
      var token=localStorage.getItem('token')
      if(!this.$store.state.poly_vertices){
       
        axios_django.get('imad/poly_vetrices/',{headers: {Authorization: `Bearer ${token}`}}).then(res=>{
        this.poly_vertices=res.data
        console.log(this.poly_vertices)
        this.$store.commit('set', ['poly_vertices', this.poly_vertices])
        //console.log('this.poly_vertices',this.$store.state.poly_vertices)
      })
      }
      
    }
    
  
  }
 

};
</script>
